import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AccountsService } from '@lib/didit-accounts-data-accounts-service';

export const hasBasicAccountInfo: CanActivateFn = async () => {
    const router = inject(Router);
    const accountsService = inject(AccountsService);
    const activeAccount = await firstValueFrom(accountsService.activeAccount$);

    if (!activeAccount) return router.parseUrl('/account/login');
    if (activeAccount.fullName && activeAccount.use) return true;

    return router.parseUrl('/account/basic-information');
};
