import { Enum, Format, JsonSchema, Optional, Required } from 'ts-decorator-json-schema-generator';
import { RxdbAskStatusChange } from './rxdb-ask.interface';
import { ASK_STATUSES, AskStatus } from '@lib/shared-interface-ask';

@JsonSchema({
    title: 'StatusChanges Schema',
    description: 'An asks statusChanges.',
})
export class StatusChangesSchema implements RxdbAskStatusChange {
    @Required()
    @Format('date-time')
    public readonly timestamp!: string;

    @Required()
    @Enum(ASK_STATUSES, 'string')
    public readonly status!: AskStatus;

    @Optional()
    public readonly note?: string;
}
