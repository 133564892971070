import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, fromEvent, merge, EMPTY } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { WINDOW, NAVIGATOR } from '@lib/shared-util-angular-globals';

@Injectable({ providedIn: 'root' })
export class OnlineStatusService {
    public readonly wentOnline$: Observable<Event> = this.window
        ? fromEvent(this.window, 'online')
        : EMPTY;
    public readonly wentOffline$: Observable<Event> = this.window
        ? fromEvent(this.window, 'offline')
        : EMPTY;
    public readonly isOnline$: Observable<boolean> = merge(
        this.wentOnline$.pipe(map(() => true)),
        this.wentOffline$.pipe(map(() => false)),
    ).pipe(startWith(this.navigator?.onLine ?? false), shareReplay(1));
    public readonly isOffline$: Observable<boolean> = this.isOnline$.pipe(
        map((online) => !online),
        shareReplay(1),
    );

    public constructor(
        @Optional() @Inject(WINDOW) private readonly window?: Window,
        @Optional() @Inject(NAVIGATOR) private readonly navigator?: Navigator,
    ) {}
}
