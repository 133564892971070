import { Options } from 'rrule';
import { Frequency } from './frequency-type';
import { WeekdayAbbreviation } from './weekday-abbreviation-type';
import { DateTime } from 'luxon';

export type RecurrenceOptions = Partial<
    Omit<Options, 'dtstart' | 'tzid' | 'freq' | 'interval' | 'byweekday' | 'count' | 'until'>
> & {
    freq: Frequency;
    interval: number;
    byweekday: WeekdayAbbreviation[] | undefined;
    endAfterCount?: number | undefined;
    until?: DateTime;
} & {
    // This should already be in the desired timezone
    // We set the timezone for the RRuleSet
    // based on the timezone on this DateTime
    firstOccurrence: DateTime;
};
