import { Email, Phone, Uuid } from '@lib/shared-interface-utility-types';
import { createStringEnum, EnumValues } from '@lib/shared-util-create-enum';
import { SubscriberPlan } from '@lib/shared-interface-subscriber-plan';
import { FullAccount } from './full-account.interface';

/**
 * Contains properties of a user account shared between the client and server.
 *
 * There may be additional fields on the server side.
 */
export interface Account {
    /**
     * Unique ID for this account.
     */
    id: Uuid;
    /**
     * Label for the account.
     *
     * This can help to distinguish this account from other accounts managed by a single user.
     */
    label?: string;
    /**
     * The full name so that we know how to refer to the person behind this account.
     */
    fullName?: string;
    /**
     * The email so that others can reach this account.
     */
    email: Email;
    /**
     * Phone number in E.164 format.
     */
    phone?: Phone;
    /**
     * Date of birth so that we know the age of whomever is behind this account.
     */
    dateOfBirth?: DateOfBirth;
    /**
     * The stated use of the account.
     */
    use?: AccountUse;
    /**
     * The ISO-3166 Alpha 2 country code for the home country.
     */
    homeCountryCode?: string;
    /**
     * The ISO-639-1 two-letter language code for the preferred language.
     */
    languageCode?: string;
    /**
     * The base64 encoded image uploaded by the user
     */
    avatar?: string;
    /**
     * Id of the plan in the Didit database
     */
    subscriberPlanId?: Uuid;
    /**
     * Optionally populated plan for this account
     */
    subscriberPlan?: SubscriberPlan;
    /**
     * When the account data was last updated.
     * This is necessary for replication.
     */
    updatedAt: Date;
}

/**
 * Internal structure used to store a user's DateOfBirth.
 */
export interface DateOfBirth {
    /**
     * Year should be all 4 digits (full year)
     */
    year: number;
    /**
     * Month should be a value 1 - 12 (not 0 - 11)
     */
    month: number;
    /**
     * Day should be a value 1 - 31 (not 0 - 30)
     */
    day?: number;
}

/**
 * Basic account info which is required to be considered registered.
 *
 * i.e. without a "label" and "fullName", account cannot yet use the main features of Didit.
 */
export type BasicAccountInfo = Required<Pick<FullAccount, 'fullName' | 'use'>> &
    Pick<FullAccount, 'phone'>;

/**
 * Intended uses of an account.
 */
export const AccountUse = createStringEnum(['PERSONAL', 'WORK', 'OTHER']);
export type AccountUse = EnumValues<typeof AccountUse>;
export const ACCOUNT_USES: AccountUse[] = Object.values(AccountUse);
