import { Enum, JsonSchema, Optional, Required } from 'ts-decorator-json-schema-generator';
import { Uuid } from '@lib/shared-interface-utility-types';
import { UuidFormat } from '../../shared/uuid-format.decorator';
import { ACCEPTANCE_TYPES, AcceptanceType, Requester } from '@lib/shared-interface-ask';

@JsonSchema({
    title: 'Requester Schema',
    description: 'Metadata about a requester in relation to a specific ask.',
})
export class RequesterSchema implements Requester {
    @Required()
    @UuidFormat()
    public readonly accountId!: Uuid;

    @Optional()
    @Enum(ACCEPTANCE_TYPES, 'string')
    public readonly acceptance?: AcceptanceType;
}
