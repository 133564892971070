import { DOCUMENT } from '@angular/common';
import { InjectionToken, Provider } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('WINDOW');

export const WINDOW_PROVIDER: Provider = {
    provide: WINDOW,
    useFactory: (document: Document) => {
        return document.defaultView;
    },
    deps: [DOCUMENT],
};
