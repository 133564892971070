import { RRule, RRuleSet, Weekday, Options } from 'rrule';
import { DateTime } from 'luxon';
import { Frequency, isFrequency } from './types/frequency-type';
import { WeekdayAbbreviation, isWeekdayAbbreviation } from './types/weekday-abbreviation-type';
import { RecurrenceOptions } from './types/recurrence-options-type';
import { ERROR_TEXT, assertRruleSetValid, isRruleWeekday } from './utils/guards';
import { TerminationStrategy } from './types/termination-strategy-type';

export class RecurrencePattern {
    private rruleSet: RRuleSet;

    public static createFromRruleString(rrule: string): RecurrencePattern {
        const rruleSet = new RRuleSet();
        rruleSet.rrule(RRule.fromString(rrule));
        return new RecurrencePattern(rruleSet);
    }

    public static createFromOptions(options: RecurrenceOptions): RecurrencePattern {
        const rRuleSet = new RRuleSet();
        const { firstOccurrence, byweekday, endAfterCount, until, ...passThroughOptions } = options;

        // Using "count" and "until" properties simultaneously
        // is ambiguous and confusing
        if (endAfterCount && until) {
            throw new TypeError(ERROR_TEXT);
        }

        const rRuleOptions = {
            ...passThroughOptions,
            dtstart: new Date(firstOccurrence.toUTC(undefined, { keepLocalTime: true }).valueOf()),
            tzid: firstOccurrence.zone.name,
            byweekday: byweekday?.map((weekday) => {
                return Weekday.fromStr(weekday);
            }),
            count: endAfterCount,
            until: until
                ? new Date(until.toUTC(undefined, { keepLocalTime: true }).valueOf())
                : undefined,
        };
        rRuleSet.rrule(new RRule(rRuleOptions));
        return new RecurrencePattern(rRuleSet);
    }

    private constructor(rruleSet: RRuleSet) {
        this.rruleSet = rruleSet;
    }

    public get firstOccurrence(): DateTime {
        const { dtstart, tzid } = this.rruleOptions;
        if (!dtstart) {
            throw new TypeError(ERROR_TEXT);
        }
        const timezone = tzid ?? 'local';
        const utcDateTime = DateTime.fromJSDate(dtstart, { zone: 'utc' });
        return utcDateTime.setZone(timezone, { keepLocalTime: true });
    }

    public get timezone(): string {
        // RRule library types the "tzid" property as any
        return (this.rruleSet.tzid as () => string)?.();
    }

    public get frequency(): Frequency {
        const frequency = this.rruleOptions.freq;
        if (!isFrequency(frequency)) {
            throw new TypeError(ERROR_TEXT);
        }
        return frequency;
    }

    public get interval(): number {
        const interval = this.rruleOptions.interval;
        if (!interval) {
            throw new TypeError(ERROR_TEXT);
        }
        return interval;
    }

    public get byweekday(): WeekdayAbbreviation[] | undefined {
        const weekdays = this.rruleOptions.byweekday;
        if (Array.isArray(weekdays)) {
            if (weekdays.every(isWeekdayAbbreviation)) {
                return weekdays;
            }

            if (weekdays.every(isRruleWeekday)) {
                const abbreviations = weekdays.map((weekday) => {
                    return weekday.toString();
                });
                if (abbreviations.every(isWeekdayAbbreviation)) {
                    return abbreviations;
                }
            }
        }

        if (isWeekdayAbbreviation(weekdays)) {
            return [weekdays];
        }
        return undefined;
    }

    public get until(): DateTime | undefined {
        const until = this.rruleOptions.until;
        if (until == undefined) {
            return undefined;
        }
        return DateTime.fromJSDate(until);
    }

    public get endAfterCount(): number | undefined {
        return this.rruleOptions.count ?? undefined;
    }

    public get terminationStrategy(): TerminationStrategy {
        const options = this.rruleOptions;
        const until = options.until;
        const count = options.count;
        if (until) {
            return TerminationStrategy.endDate;
        }
        if (count) {
            return TerminationStrategy.afterOccurrences;
        }
        return TerminationStrategy.never;
    }

    // This method must be present
    // so that RxDB knows how to serialize recurrence patterns
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public toJSON(): string {
        return this.toString();
    }

    public toString(): string {
        return this.rruleSet.toString();
    }

    public toDescription(): string {
        const rRules = this.rruleSet.rrules();
        if (rRules[0]) {
            return rRules[0].toText();
        }
        return this.rruleSet.toText();
    }

    public getInstances(start: DateTime, end: DateTime): DateTime[] {
        return this.rruleSet.between(start.toJSDate(), end.toJSDate()).map((date) => {
            // See section on "Use UTC dates" from the documentation:
            // https://www.npmjs.com/package/rrule
            const wrongTimeWrongZone = DateTime.fromISO(date.toISOString(), {
                zone: 'utc',
            });
            const rightTimeWrongZone = wrongTimeWrongZone.setZone('local', {
                keepLocalTime: true,
            });
            return rightTimeWrongZone.setZone(this.timezone);
        });
    }

    private get rruleOptions(): Partial<Options> {
        assertRruleSetValid(this.rruleSet);
        return this.rruleSet.rrules()[0].origOptions;
    }
}
