import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Uuid } from '@lib/shared-interface-utility-types';
import { GRAPHQL_DOMAIN_URL_TOKEN, GraphqlDomain } from './provide-graphql-configuration';
import { Account, BasicAccountInfo } from '@lib/shared-interface-account';
import { GraphqlErrorsException } from './errors/graphql-errors.exception';
import { ApiUnreachableError } from './api-unreachable-error';
import { SaveBasicAccountInfoGql } from './queries/save-basic-account-info.gql';
import { GetAuthenticatedAccountGql } from './queries/get-authenticated-account.gql';
import { UpdateAccountEmailGql } from './queries/update-account-email.gql';

@Injectable({
    providedIn: 'root',
})
export class GraphqlClientService {
    public constructor(
        private http: HttpClient,
        @Inject(GRAPHQL_DOMAIN_URL_TOKEN) private graphqlDomain: GraphqlDomain,
        private readonly getAuthenticatedAccountGql: GetAuthenticatedAccountGql,
        private readonly saveBasicAccountInfoGql: SaveBasicAccountInfoGql,
        private readonly updateAccountEmailGql: UpdateAccountEmailGql,
    ) {}

    public async assertReachable(): Promise<void> {
        // If you have mission-critical data
        // that needs to be sent to the backend as quickly as possible or not at all,
        // you can use this method to assert the API is reachable before performing an action.
        const response$ = this.http.head(this.graphqlDomain + '/health');
        try {
            await firstValueFrom(response$);
        } catch {
            throw new ApiUnreachableError();
        }
    }

    public async getAuthenticatedAccount(headers: AuthenticationHeaders): Promise<Account> {
        const options = { headers };
        const response$ = this.getAuthenticatedAccountGql.fetch(undefined, options);
        const response = await lastValueFrom(response$);
        if (response.data == undefined) throw new GraphqlErrorsException(response.errors);

        return response.data.authenticatedAccount;
    }

    public async saveBasicAccountInfo(basicAccountInfo: BasicAccountInfo): Promise<Uuid> {
        const response$ = this.saveBasicAccountInfoGql.mutate({ basicAccountInfo });
        const response = await lastValueFrom(response$);
        if (response.data == undefined) throw new GraphqlErrorsException(response.errors);

        return response.data.saveBasicAccountInfo.id;
    }

    public async updateAccountEmail(): Promise<Uuid> {
        const response$ = this.updateAccountEmailGql.mutate();
        const response = await lastValueFrom(response$);
        if (response.data == undefined) throw new GraphqlErrorsException(response.errors);

        return response.data.updateAccountEmail.id;
    }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type AuthenticationHeaders = { Authorization: string };
