import { Contact } from '@lib/shared-interface-contact';
import { Jsonify } from 'type-fest';

export type RxdbContact = Jsonify<Contact>;

export function reviveRxdbContact(rxdbContact: RxdbContact): Contact {
    const updatedAt = new Date(rxdbContact.updatedAt);

    return { ...rxdbContact, updatedAt };
}
