import { Enum, Format, JsonSchema, Optional, Required } from 'ts-decorator-json-schema-generator';
import { RxdbRecipientAskStatusChange } from './rxdb-ask.interface';
import { RECIPIENT_ASK_STATUSES, RecipientAskStatus } from '@lib/shared-interface-ask';

@JsonSchema({
    title: 'Recipient Status Changes Schema',
    description: 'Status changes of the ask recipients',
})
export class RecipientStatusChangesSchema implements RxdbRecipientAskStatusChange {
    @Required()
    @Format('date-time')
    public readonly timestamp!: string;

    @Required()
    @Enum(RECIPIENT_ASK_STATUSES, 'string')
    public readonly status!: RecipientAskStatus;

    @Optional()
    public readonly note?: string;
}
