@if (imageSource) {
    <ion-avatar>
        <img alt="user avatar" [src]="imageSource" [style.width.px]="width" class="avatar-image" />
    </ion-avatar>
} @else {
    @if (titleInitial) {
        <div
            [style.width]="width + 'px'"
            [style.height]="width + 'px'"
            [style.font-size]="fontSize"
            [style.background-color]="backgroundColor"
            class="account-avatar-icon"
        >
            {{ titleInitial }}
        </div>
    } @else {
        <ion-avatar>
            <ion-icon
                [style.font-size]="fontSize"
                [style.height]="width + 'px'"
                [style.width]="width + 'px'"
                color="primary"
                name="person-circle-outline"
            />
        </ion-avatar>
    }
}
