import { createRxDatabase, RxCollectionCreator } from 'rxdb';
import { AppCollections, AppDatabase } from './app-database.interface';
import { RxdbAccountReference } from './account-references-collection/rxdb-account-reference.interface';
import { ACCOUNT_REFERENCE_SCHEMA } from './account-references-collection/account-reference.schema';
import { getCommonDatabaseOptions } from '../shared/get-common-database-options';

/**
 * Ensure the app database is created only once.
 */
export function getAppDatabase(): Promise<AppDatabase> {
    if (appDatabase != undefined) return appDatabase;

    appDatabase = createAppDatabase();
    return appDatabase;
}

let appDatabase: Promise<AppDatabase>;

async function createAppDatabase(): Promise<AppDatabase> {
    const name = 'application';
    const commonOptions = getCommonDatabaseOptions();
    const database = await createRxDatabase<AppCollections>({ name, ...commonOptions });

    await database.addCollections<AppCollections>(collectionCreators);

    return database;
}

const collectionCreators = {
    /* eslint-disable @typescript-eslint/naming-convention */
    account_references: {
        schema: ACCOUNT_REFERENCE_SCHEMA,
    } satisfies RxCollectionCreator<RxdbAccountReference>,
    /* eslint-enable @typescript-eslint/naming-convention */
} satisfies Record<keyof AppCollections, RxCollectionCreator>;
