import { ReplicatedAccount } from './replicated-account.interface';
import { Uuid } from '@lib/shared-interface-utility-types';
import { PaymentGatewayName } from '@lib/shared-interface-payment-types';

/**
 * A user account as represented server-side.
 *
 * An account may exist with only an ID and email address for an invited user.
 */
export interface FullAccount extends ReplicatedAccount {
    /**
     * Unique ID in the authentication system.
     */
    authenticationId?: Uuid;
    /**
     * Date/time when the account accepted terms and conditions.
     */
    acceptedTermsAt?: Date;
    /**
     * All payment method data with various payment gateways
     * that have been saved by this account holder.
     */
    paymentInformation?: PaymentInformation;
}

/**
 * A customer's payment information with a specific gateway.
 */
export type PaymentInformation = {
    /**
     * The gateway that this payment information is associated with.
     */
    gateway: PaymentGatewayName;
    /**
     * The ID of the payer in the gateway's system.
     */
    payerId: string;
    /**
     * The ID of the payer's subscription in the gateway's system.
     */
    subscriptionId?: string;
};
