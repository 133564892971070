// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// eslint-disable-next-line @typescript-eslint/naming-convention
export const environment = {
    production: false,
    graphqlDomain: 'https://api.dev.didit.co',
    awsAuthentication: {
        poolData: {
            // This is the config for the DiditRxdbPrototypeUserPool
            // eslint-disable-next-line @typescript-eslint/naming-convention
            UserPoolId: 'us-west-2_MCL52Eb38',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ClientId: '1b3pv73mtt453e1fvpg94du4k4',
        },
    },
    stripe: {
        publishableKey: 'pk_test_XNHffWJVd55VyeTX8miBCBpW',
    },
};
