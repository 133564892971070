import { Jsonify } from 'type-fest';
import * as uuid from 'uuid';
import { DateTime } from 'luxon';
import { RecurrencePattern } from '@lib/shared-util-recurrence-pattern';
import {
    Ask,
    AskStatus,
    AskStatusChange,
    Recipient,
    RecipientAskStatusChange,
} from '@lib/shared-interface-ask';

// Dates and such must be transformed to primitives (and vice versa).
export type RxdbAsk = Jsonify<Ask>;
export type RxdbAskStatusChange = Jsonify<AskStatusChange>;
export type RxdbAskRecipient = Jsonify<Recipient>;
export type RxdbRecipientAskStatusChange = Jsonify<RecipientAskStatusChange>;

function reviveRxdbRecipients(recipient: RxdbAskRecipient): Recipient {
    const statusChanges = recipient.statusChanges.map(reviveRxdbRecipientStatusChange);
    return { ...recipient, statusChanges };
}

export function reviveRxdbAsk(rxdbAsk: RxdbAsk): Ask {
    const createdAt = new Date(rxdbAsk.createdAt);
    const updatedAt = new Date(rxdbAsk.updatedAt);
    const trashedAt =
        rxdbAsk.trashedAt == undefined ? rxdbAsk.trashedAt : new Date(rxdbAsk.trashedAt);
    const completedAt =
        rxdbAsk.completedAt == undefined ? rxdbAsk.completedAt : new Date(rxdbAsk.completedAt);
    const sentAt = rxdbAsk.sentAt == undefined ? rxdbAsk.sentAt : new Date(rxdbAsk.sentAt);
    const statusChanges = rxdbAsk.statusChanges.map(reviveRxdbAsksStatusChange);
    const recipients = rxdbAsk.recipients?.map(reviveRxdbRecipients);
    const recurrencePattern = rxdbAsk.recurrencePattern
        ? RecurrencePattern.createFromRruleString(rxdbAsk.recurrencePattern)
        : undefined;

    return {
        ...rxdbAsk,
        createdAt,
        updatedAt,
        sentAt,
        completedAt,
        trashedAt,
        recurrencePattern,
        statusChanges,
        recipients,
    };
}

/**
 * Minimal input required to create an ask.
 */
export type CreateAskInput = Omit<
    Ask,
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'trashedAt'
    | 'completedAt'
    | 'statusChanges'
    | 'currentStatus'
>;

export function createRxdbAskInsert(input: CreateAskInput): RxdbAsk {
    const id = uuid.v4();
    const date = new Date().toJSON();
    const createdAt = date;
    const updatedAt = date;
    const sentAt = input.sentAt?.toJSON();
    const statusChanges = [{ timestamp: date, status: AskStatus.DRAFT }];
    const currentStatus = AskStatus.DRAFT;
    const recurrencePattern = input.recurrencePattern?.toJSON();
    const recipients = input.recipients?.map(createRxdbRecipient) ?? [];

    return {
        id,
        ...input,
        currentStatus,
        createdAt,
        updatedAt,
        sentAt,
        statusChanges,
        recurrencePattern,
        recipients,
    };
}

// ID & created at cannot be changed. Updated at will always be overwritten.
export type PatchAskInput = Partial<Omit<Ask, 'id' | 'creatorId' | 'createdAt' | 'updatedAt'>>;
type PatchAskOutput = Pick<Ask, 'updatedAt'> & Partial<Omit<Ask, 'id' | 'createdAt'>>;
type RxdbPatchAskOutput = Jsonify<PatchAskOutput>;

export function createRxdbAskPatch(input: PatchAskInput): RxdbPatchAskOutput {
    const {
        completedAt,
        trashedAt,
        sentAt,
        recurrencePattern,
        statusChanges,
        recipients,
        ...remaining
    } = input;
    // On an update, we should always change the updatedAt property.
    const updatedAt = DateTime.now().toISO();
    const patchData: RxdbPatchAskOutput = {
        ...remaining,
        updatedAt,
    };
    // Avoid always explicitly setting the property to undefined
    // as this could unintentionally clear the property.
    if ('sentAt' in input) {
        patchData.sentAt = sentAt?.toJSON();
    }
    if ('completedAt' in input) {
        patchData.completedAt = completedAt?.toJSON();
    }
    if ('trashedAt' in input) {
        patchData.trashedAt = trashedAt?.toJSON();
    }
    if ('recurrencePattern' in input) {
        patchData.recurrencePattern = recurrencePattern?.toJSON();
    }
    if ('statusChanges' in input) {
        patchData.statusChanges = statusChanges?.map(createRxdbStatusChange);
    }
    if ('recipients' in input) {
        patchData.recipients = recipients?.map(createRxdbRecipient);
    }

    return patchData;
}

function reviveRxdbAsksStatusChange(statusChange: RxdbAskStatusChange): AskStatusChange {
    return { ...statusChange, timestamp: new Date(statusChange.timestamp) };
}

function reviveRxdbRecipientStatusChange(
    statusChange: RxdbRecipientAskStatusChange,
): RecipientAskStatusChange {
    return { ...statusChange, timestamp: new Date(statusChange.timestamp) };
}

function createRxdbStatusChange(statusChange: AskStatusChange): RxdbAskStatusChange {
    return { ...statusChange, timestamp: statusChange.timestamp.toJSON() };
}

function createRxdbRecipient(recipient: Recipient): RxdbAskRecipient {
    return {
        ...recipient,
        statusChanges: recipient.statusChanges.map(createRxdbRecipientStatusChange),
    };
}

function createRxdbRecipientStatusChange(
    statusChange: RecipientAskStatusChange,
): RxdbRecipientAskStatusChange {
    return { ...statusChange, timestamp: statusChange.timestamp.toJSON() };
}
