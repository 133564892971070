import { JsonSchema, Required } from 'ts-decorator-json-schema-generator';
import {
    SubscriberPlan,
    SubscriberPlanPrice,
    SubscriberPlanType,
} from '@lib/shared-interface-subscriber-plan';
import { UuidFormat } from '../../shared/uuid-format.decorator';

@JsonSchema({
    title: 'Subscriber Plan Schema',
    description: 'A subscriber plan attached to an account',
})
export class SubscriberPlanSchema implements SubscriberPlan {
    @Required()
    @UuidFormat()
    public readonly id!: string;

    @Required()
    public readonly title!: string;

    @Required()
    public readonly description!: string;

    @Required()
    public readonly pricing!: SubscriberPlanPrice[];

    @Required()
    public readonly type!: SubscriberPlanType;

    @Required()
    public readonly default!: boolean;
}
