import { AssignmentStrategy, Recipient } from './ask.interface';

export function adjustAssignmentStrategy(
    currentStrategy: AssignmentStrategy | undefined,
    recipients: Recipient[],
): AssignmentStrategy | undefined {
    const assignees = recipients.filter((recipient) => recipient.assignee);
    if (currentStrategy && assignees.length < 2) return undefined;
    if (!currentStrategy && assignees.length > 1) return AssignmentStrategy.MULTIPLE;

    return currentStrategy;
}
