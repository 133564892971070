import { Uuid } from '@lib/shared-interface-utility-types';
import { createContactReplicationOptions } from './contacts-collection/create-contact-replication-options';
import { replicateGraphQL, RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';
import { AccountDatabase } from './account-database.interface';
import { createAccountReplicationOptions } from './account-collection/create-account-replication-options';
import { createAskReplicationOptions } from './asks-collection/create-ask-replication-options';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const REPLICATION_STATES = new Map<Uuid, RxGraphQLReplicationState<any, any>[]>();

export async function awaitInitialReplication(accountId: Uuid): Promise<void> {
    const replicationStates = REPLICATION_STATES.get(accountId);
    if (replicationStates === undefined) throw new Error('Something went wrong...');

    for (const replicationState of replicationStates)
        await replicationState.awaitInitialReplication();
}

export function startAccountDatabaseReplication(
    accountId: Uuid,
    database: AccountDatabase,
    commonOptions: CommonSyncOptions,
): void {
    // Replication states might already exist.
    let replicationStates = REPLICATION_STATES.get(accountId);
    if (replicationStates != undefined) {
        const headers = commonOptions.headers ?? {};
        for (const state of replicationStates) state.setHeaders(headers);
        return;
    }

    // Turn on replication for all of its collections.
    replicationStates = [];

    const collectionReplicationOptions: CollectionSpecificSyncOptions[] = [
        createAccountReplicationOptions(accountId, database.account),
        createAskReplicationOptions(accountId, database.asks),
        createContactReplicationOptions(accountId, database.contacts),
    ];
    for (const replicationOptions of collectionReplicationOptions) {
        const fullReplicationOptions = { ...replicationOptions, ...commonOptions };
        const replicationState = replicateGraphQL(fullReplicationOptions);

        replicationStates.push(replicationState);
    }

    REPLICATION_STATES.set(accountId, replicationStates);
}

export async function stopAccountDatabaseReplication(accountId: Uuid): Promise<void> {
    // Pull up the replication state for this account and cancel replication.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const replicationStates = REPLICATION_STATES.get(accountId);
    if (replicationStates == undefined) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const cancellations = replicationStates.map((state) => state.cancel());
    await Promise.allSettled(cancellations);

    REPLICATION_STATES.delete(accountId);
}

export type CommonSyncOptions = Pick<
    SyncOptionsGraphQl,
    'url' | 'headers' | 'replicationIdentifier'
>;

// Todo: should be `SyncOptionsGraphQL<unknown, unknown>`
//  but `SyncOptionsGraphQL` currently isn't exported correctly from rxdb.
type SyncOptionsGraphQl = Parameters<typeof replicateGraphQL>[0];
type CollectionSpecificSyncOptions = Omit<
    SyncOptionsGraphQl,
    'url' | 'headers' | 'replicationIdentifier'
>;
