import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { IonButton, IonInput, ModalController } from '@ionic/angular/standalone';
import {
    AsYouType,
    CountryCode,
    isValidPhoneNumber,
    parsePhoneNumber,
    PhoneNumber,
} from 'libphonenumber-js/min';
import { Subscription } from 'rxjs';
import { PhoneNumberCountryListModal } from './phone-number-country-list-modal/phone-number-country-list.modal';
import { NgOptimizedImage } from '@angular/common';
import { CountryFlagIconPipe } from './pipes/country-flag-icon.pipe';
import { ExamplePhoneNumberPipe } from './pipes/example-phone-number.pipe';
import { provideValidator, provideValueAccessor } from '@lib/shared-ui-ionic-form-utilities';

const DEFAULT_LOCALE = 'en-US';
const DEFAULT_COUNTRY_CODE = 'US';

@Component({
    standalone: true,
    selector: 'lib-phone-number-control',
    templateUrl: './phone-number.control.html',
    styleUrls: ['./phone-number.control.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        IonButton,
        IonInput,
        ReactiveFormsModule,
        NgOptimizedImage,
        CountryFlagIconPipe,
        ExamplePhoneNumberPipe,
    ],
    providers: [
        provideValueAccessor(() => PhoneNumberControl),
        provideValidator(() => PhoneNumberControl),
    ],
})
export class PhoneNumberControl implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    @Input() public autocomplete = 'off';
    @Input() public label?: string | null;

    protected notifyTouched?: FormTouchHandler;
    protected notifyChange?: FormChangeHandler;
    protected readonly formGroup = this.formBuilder.group({
        countryCode: this.formBuilder.control<CountryCode>(DEFAULT_COUNTRY_CODE),
        phoneNumber: this.formBuilder.control(''),
    });

    @ViewChild('phoneNumberInputElement') private phoneNumberInputElement!: IonInput;

    private subscriptions = new Subscription();
    private lastPhoneNumberValue = '';
    private asYouTypeFormatter = new AsYouType(DEFAULT_COUNTRY_CODE);
    private externalControl?: AbstractControl;

    public constructor(
        private readonly changeDetector: ChangeDetectorRef,
        private readonly formBuilder: NonNullableFormBuilder,
        private readonly modalController: ModalController,
    ) {}

    public ngOnInit(): void {
        const countryCodeChange$ = this.formGroup.controls.countryCode.valueChanges;
        const countryCodeSubscription = countryCodeChange$.subscribe((countryCode) => {
            this.asYouTypeFormatter = new AsYouType(countryCode);
            this.formGroup.controls.phoneNumber.reset();
        });

        const phoneNumberChange$ = this.formGroup.controls.phoneNumber.valueChanges;
        const phoneNumberSubscription = phoneNumberChange$.subscribe((phoneNumber) => {
            this.asYouTypeFormatter.reset();
            let formattedPhoneNumber = this.asYouTypeFormatter.input(phoneNumber);

            // Change must have been a backspace on a non-numeric character
            // Without this, the user could get stuck inputting "(123)"
            // because whenever they backspace the right parentheses,
            // the formatter would add it back in
            if (formattedPhoneNumber === this.lastPhoneNumberValue) {
                const lastCharacter = formattedPhoneNumber.slice(-1);
                if (!/\d/.test(lastCharacter)) {
                    formattedPhoneNumber = formattedPhoneNumber.slice(0, -1);
                }
            }

            this.formGroup.controls.phoneNumber.setValue(formattedPhoneNumber, {
                emitEvent: false,
            });
            this.lastPhoneNumberValue = formattedPhoneNumber;
        });

        const formGroupChange$ = this.formGroup.valueChanges;
        const formChangeSubscription = formGroupChange$.subscribe(() => {
            const { phoneNumber, countryCode } = this.formGroup.getRawValue();
            const parsedPhoneNumber = safelyCompilePhoneNumber(phoneNumber, countryCode);

            if (parsedPhoneNumber && parsedPhoneNumber.isValid())
                return this.notifyChange?.(parsedPhoneNumber.number);

            this.notifyChange?.(phoneNumber);
        });

        this.subscriptions.add(countryCodeSubscription);
        this.subscriptions.add(phoneNumberSubscription);
        this.subscriptions.add(formChangeSubscription);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public writeValue(newPhoneNumber: string): void {
        const rawPhoneNumber = newPhoneNumber ? safelyParsePhoneNumber(newPhoneNumber) : undefined;
        const defaultCountryCode: CountryCode = 'US';

        if (!rawPhoneNumber)
            return this.formGroup.patchValue({ countryCode: defaultCountryCode, phoneNumber: '' });

        const formValue = {
            countryCode: rawPhoneNumber.country ?? defaultCountryCode,
            phoneNumber: rawPhoneNumber.formatNational(),
        };
        this.formGroup.patchValue(formValue);
    }

    public registerOnChange(notifyChange: FormChangeHandler): void {
        this.notifyChange = notifyChange;
    }

    public registerOnTouched(notifyTouched: FormTouchHandler): void {
        this.notifyTouched = notifyTouched;
    }

    public setDisabledState(isDisabled: boolean): void {
        const methodName = isDisabled ? 'disable' : 'enable';
        this.formGroup.controls.countryCode[methodName]();
        this.formGroup.controls.phoneNumber[methodName]();
    }

    public validate(externalControl: AbstractControl): ValidationErrors | null {
        this.mirrorExternalValidator(externalControl);
        const { countryCode, phoneNumber } = this.formGroup.controls;
        const phoneNumberValue = phoneNumber.value ?? '';

        // eslint-disable-next-line unicorn/no-null
        if (phoneNumberValue.length === 0) return null;

        return validatePhoneNumber(phoneNumberValue, countryCode.value);
    }

    public async openCountrySelectModal(): Promise<void> {
        const modal = await this.modalController.create({
            component: PhoneNumberCountryListModal,
            componentProps: {
                locale: DEFAULT_LOCALE,
            },
        });
        await modal.present();

        const { data: countryCode, role } = await modal.onDidDismiss<CountryCode>();
        this.notifyTouched?.();

        if (role !== 'confirm' || !countryCode) return;

        this.formGroup.controls.countryCode.setValue(countryCode);
        const input = await this.phoneNumberInputElement.getInputElement();
        input.focus();

        // With OnPush ChangeDetection the parent won't update the flag
        // until the next time change detection runs without explicitly telling it to.
        this.changeDetector.markForCheck();
    }

    public setFocus() {
        // Todo: Is was a better or more standard way to drill down and focus this input?
        //  Is there a standard interface (like `ControlViewAccessor` or `Validator`) for this?
        return this.phoneNumberInputElement.setFocus();
    }

    private mirrorExternalValidator(externalControl: AbstractControl) {
        // We only need to do this one time.
        if (this.externalControl) return;

        this.externalControl = externalControl;

        const { phoneNumber } = this.formGroup.controls;
        const externalControlValidator = externalControl.validator;
        if (!externalControlValidator) return;

        // This ensures the control shows red on blur if no edits were made.
        phoneNumber.addValidators(externalControlValidator);
    }
}

type FormChangeHandler = (standardizedPhoneNumber?: string | undefined) => void;
type FormTouchHandler = () => void;

function validatePhoneNumber(
    phoneNumber: string,
    countryCode: CountryCode,
): ValidationErrors | null {
    const isValid = isValidPhoneNumber(phoneNumber, countryCode);
    // eslint-disable-next-line unicorn/no-null
    if (isValid) return null;

    return { phoneNumber: true };
}

function safelyParsePhoneNumber(phoneNumber: string): PhoneNumber | undefined {
    // Try/catch is necessary since parsePhoneNumber() throws an error
    // in the case of certain parsing failures.
    try {
        return parsePhoneNumber(phoneNumber);
    } catch {
        return;
    }
}

function safelyCompilePhoneNumber(
    number: string,
    countryCode: CountryCode,
): PhoneNumber | undefined {
    // Try/catch is necessary since parsePhoneNumber() throws an error
    // in the case of certain parsing failures/
    try {
        return parsePhoneNumber(number, countryCode);
    } catch {
        return;
    }
}
