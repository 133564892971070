import { RRule } from 'rrule';

export const Frequency = {
    yearly: RRule.YEARLY,
    monthly: RRule.MONTHLY,
    weekly: RRule.WEEKLY,
    daily: RRule.DAILY,
} as const;
export type Frequency = (typeof Frequency)[keyof typeof Frequency];
export function isFrequency(value: unknown): value is Frequency {
    const frequency = value as Frequency;
    return Object.values(Frequency).includes(frequency);
}
