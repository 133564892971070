import { RxDatabaseCreator } from 'rxdb';
import { Capacitor } from '@capacitor/core';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';

export function getCommonDatabaseOptions() {
    const storage = getRxStorageDexie();
    // https://capacitorjs.com/docs/core-apis/web#getplatform
    // https://rxdb.info/rx-database.html#multiinstance
    const multiInstance = Capacitor.getPlatform() === 'web';
    // https://rxdb.info/rx-database.html#eventreduce
    const eventReduce = true;

    return { eventReduce, multiInstance, storage } satisfies Partial<RxDatabaseCreator>;
}
