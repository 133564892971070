import { EnumValues } from '@lib/shared-util-create-enum';

export const WeekdayAbbreviation = {
    MONDAY: 'MO',
    TUESDAY: 'TU',
    WEDNESDAY: 'WE',
    THURSDAY: 'TH',
    FRIDAY: 'FR',
    SATURDAY: 'SA',
    SUNDAY: 'SU',
} as const;
export type WeekdayAbbreviation = EnumValues<typeof WeekdayAbbreviation>;
export const WEEKDAY_ABBREVIATIONS: WeekdayAbbreviation[] = Object.values(WeekdayAbbreviation);

export function isWeekdayAbbreviation(value: unknown): value is WeekdayAbbreviation {
    const weekday = value as WeekdayAbbreviation;
    return typeof value === 'string' && WEEKDAY_ABBREVIATIONS.includes(weekday);
}
