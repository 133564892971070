import { Ask } from './ask.interface';
import {
    ReplicationDeletable,
    ReplicationPushRow,
} from '@lib/shared-interface-rxdb-replication-types';

// TODO: RecurrencePattern should possibly be a string in the Ask interface
//  but this would change too much logic on the front end at this time
export type ReplicatedAsk = Omit<Ask, 'recurrencePattern'> &
    ReplicationDeletable & { recurrencePattern?: string };
export type ReplicatedAskPushRow = ReplicationPushRow<ReplicatedAsk>;
