import { InjectionToken, Provider } from '@angular/core';
import { WINDOW } from './window';

export const NAVIGATOR = new InjectionToken<Navigator>('NAVIGATOR');

export const NAVIGATOR_PROVIDER: Provider = {
    provide: NAVIGATOR,
    useFactory: (window: Window) => {
        return window.navigator;
    },
    deps: [WINDOW],
};
