import { Route } from '@angular/router';
import { NavigationComponent } from '@lib/didit-navigation-feature-navigation';
import { hasBasicAccountInfo } from './guards/has-basic-account-info.guard';
import { lacksBasicAccountInfo } from './guards/lacks-basic-account-info.guard';

export const APP_ROUTES: Route[] = [
    {
        path: '',
        component: NavigationComponent,
        canActivate: [hasBasicAccountInfo],
        children: [
            {
                path: 'watching',
                title: 'Watching',
                loadComponent: () => import('@lib/didit-asks-feature-watching-page'),
            },
            {
                path: 'watching/:askId',
                title: 'Watching',
                loadComponent: () => import('@lib/didit-asks-feature-watching-page'),
                data: { hideTabNavigation: true },
            },
            {
                path: 'todo',
                title: 'To Do',
                loadComponent: () => import('@lib/didit-asks-feature-to-do-page'),
            },
            {
                path: 'todo/:askId',
                title: 'To Do',
                loadComponent: () => import('@lib/didit-asks-feature-to-do-page'),
                data: { hideTabNavigation: true },
            },
            {
                path: 'drafts',
                title: 'Drafts',
                loadComponent: () => import('@lib/didit-asks-feature-drafts-page'),
            },
            {
                path: 'drafts/:askId',
                title: 'Drafts',
                loadComponent: () => import('@lib/didit-asks-feature-drafts-page'),
                data: { hideTabNavigation: true },
            },
            {
                path: 'trash',
                title: 'Trash',
                loadComponent: () => import('@lib/didit-asks-feature-trash-page'),
            },
            {
                path: 'trash/:askId',
                title: 'Trash',
                loadComponent: () => import('@lib/didit-asks-feature-trash-page'),
                data: { hideTabNavigation: true },
            },
            {
                path: 'done',
                title: 'Done',
                loadComponent: () => import('@lib/didit-asks-feature-done-page'),
            },
            {
                path: 'done/:askId',
                title: 'Done',
                loadComponent: () => import('@lib/didit-asks-feature-done-page'),
                data: { hideTabNavigation: true },
            },
            {
                path: 'explore',
                title: 'Explore',
                loadComponent: () => import('@lib/didit-asks-feature-explore-page'),
            },
            {
                path: 'account/details',
                title: 'Account',
                loadComponent: () => import('@lib/didit-accounts-feature-edit-account-page'),
            },
            {
                path: 'account/preferences',
                title: 'Account Preferences',
                loadComponent: () => import('@lib/didit-accounts-feature-preferences-page'),
            },
            {
                path: 'account/plans',
                title: 'Plans and Deals',
                loadComponent: () => import('@lib/didit-accounts-feature-plans-page'),
            },
            {
                path: 'account/manage',
                title: 'My Account',
                loadComponent: () => import('@lib/didit-accounts-feature-manage-account-page'),
            },
            {
                path: 'account/billing-history',
                title: 'Billing History',
                loadComponent: () => import('@lib/didit-accounts-feature-billing-page'),
            },
            {
                path: 'account/link',
                title: 'Link Accounts',
                loadComponent: () => import('@lib/didit-accounts-feature-link-accounts-page'),
            },
            {
                path: 'account/remove',
                title: 'Remove Account',
                loadComponent: () => import('@lib/didit-accounts-feature-remove-accounts-page'),
            },
            {
                path: 'account/subscription',
                title: 'Subscription',
                loadComponent: () =>
                    import('@lib/didit-accounts-feature-account-subscription-page'),
            },
            {
                path: 'contacts',
                title: 'Contacts',
                loadComponent: () => import('@lib/didit-contacts-feature-manage-contacts-page'),
            },
            {
                path: 'information/terms-and-conditions',
                title: 'Terms and Conditions',
                loadComponent: () =>
                    import('@lib/didit-information-feature-terms-and-conditions-page'),
            },
            {
                path: 'information/privacy-policy',
                title: 'Privacy Policy',
                loadComponent: () => import('@lib/didit-information-feature-privacy-policy-page'),
            },
            {
                path: 'information/about',
                title: 'About',
                loadComponent: () => import('@lib/didit-information-feature-about-page'),
            },
            {
                path: 'information/feedback',
                title: 'Feedback',
                loadComponent: () => import('@lib/didit-information-feature-feedback-page'),
            },
            {
                path: 'information/share',
                title: 'Tell a Friend',
                loadComponent: () => import('@lib/didit-information-feature-share-page'),
            },
            {
                path: 'information/usage',
                title: 'Usage',
                loadComponent: () => import('@lib/didit-information-feature-usage-page'),
            },
            {
                path: 'information/whats-new',
                title: "What's New",
                loadComponent: () => import('@lib/didit-information-feature-whats-new-page'),
            },
            {
                path: 'dashboard',
                title: 'Dashboard',
                loadComponent: () => import('@lib/didit-asks-feature-dashboard-page'),
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'account/login',
        title: 'Log In',
        loadComponent: () => import('@lib/didit-authentication-feature-login-page'),
    },
    {
        path: 'account/register',
        title: 'Account Registration',
        loadComponent: () => import('@lib/didit-authentication-feature-register-page'),
    },
    {
        path: 'account/confirm-registration',
        redirectTo: 'account/register',
        pathMatch: 'full',
    },
    {
        path: 'account/confirm-registration/:email',
        title: 'Confirm Registration',
        loadComponent: () => import('@lib/didit-authentication-feature-confirm-registration-page'),
    },
    {
        path: 'account/basic-information',
        title: 'Basic Information',
        loadComponent: () => import('@lib/didit-authentication-feature-basic-information-page'),
        canActivate: [lacksBasicAccountInfo],
    },
    {
        path: 'account/plan-selection',
        title: 'Plan Selection',
        loadComponent: () => import('@lib/didit-authentication-feature-plan-selection-page'),
    },
    {
        path: 'account/payment-information',
        title: 'Payment Information',
        loadComponent: () => import('@lib/didit-authentication-feature-payment-information-page'),
    },
    {
        path: 'account/reset-password',
        title: 'Password Reset',
        loadComponent: () => import('@lib/didit-authentication-feature-reset-password-page'),
    },
    {
        path: 'account/change-password',
        redirectTo: 'account/reset-password',
        pathMatch: 'full',
    },
    {
        path: 'account/change-password/:email',
        title: 'Change Password',
        loadComponent: () => import('@lib/didit-authentication-feature-change-password-page'),
    },
];
