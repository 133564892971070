import { Environment } from './environment.interface';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const environment: Environment = {
    serviceWorkerEnabled: true,
    graphqlDomain: 'https://api.dev.didit.co',
    awsAuthentication: {
        poolData: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            UserPoolId: 'us-west-2_MCL52Eb38',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ClientId: '1b3pv73mtt453e1fvpg94du4k4',
        },
    },
    stripe: {
        publishableKey: 'pk_test_XNHffWJVd55VyeTX8miBCBpW',
    },
};
