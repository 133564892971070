import { ActiveAccountIdStore } from './active-account-id-store.service';
import { AccountReference } from '@lib/didit-accounts-interface-account-reference';
import { Uuid } from '@lib/shared-interface-utility-types';
import { firstValueFrom, Observable, shareReplay } from 'rxjs';
import { DatabaseService, PatchAccountInput } from '@lib/didit-shared-data-database-service';
import { switchMapIfDefined } from '@lib/shared-util-rxjs';
import { Injectable } from '@angular/core';
import { Account } from '@lib/shared-interface-account';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AccountsService {
    /**
     * Observable that emits whenever the active account is updated.
     */
    public readonly activeAccount$: Observable<Account | undefined> =
        this.activeAccountIdStore.item$.pipe(
            switchMapIfDefined((id) => this.databaseService.watchAccount(id)),
            shareReplay(1),
        );

    public constructor(
        private readonly activeAccountIdStore: ActiveAccountIdStore,
        private readonly databaseService: DatabaseService,
        private readonly router: Router,
    ) {}

    /**
     * Adds an account.
     *
     * Makes the new account active, and updates the accounts$ and activeAccount$ observables.
     */
    public async add(accountReference: AccountReference): Promise<void> {
        await this.databaseService.addAccountReference(accountReference);
        await this.setActive(accountReference.id);
    }

    /**
     * Update an account's details.
     *
     * If successful, the accounts$ observable is expected to update.
     */
    public async update(id: Uuid, patch: PatchAccountInput): Promise<void> {
        await this.databaseService.patchAccount(id, patch);
    }

    /**
     * Sets the last active account.
     *
     * When a user makes an account the active account, store this information on the account.
     * Updates the activeAccount$ observable.
     */
    public async setActive(id: Uuid | undefined): Promise<void> {
        await this.activeAccountIdStore.update(id);
    }

    public async clearAllLastViewedPages(): Promise<void> {
        const accounts = await firstValueFrom(this.databaseService.accountReferences$);
        for (const account of accounts) removeRedirectUrl(account.id);
    }

    public async saveLastViewedPage(currentPage: string): Promise<void> {
        const id = await firstValueFrom(this.activeAccountIdStore.item$);
        if (!id) return;

        const accountUrlPattern = /account\//;
        if (accountUrlPattern.test(currentPage)) return;

        setRedirectUrl(id, currentPage);
    }

    public async navigateToLastViewedPage(accountId: Uuid): Promise<void> {
        const activeAccountRedirectUrl = getRedirectUrl(accountId);
        const redirectUrl = activeAccountRedirectUrl?.length ? activeAccountRedirectUrl : '';
        await this.router.navigateByUrl(redirectUrl);
    }
}

function setRedirectUrl(accountId: Uuid, url: string) {
    const storageKey = getRedirectUrlStorageKey(accountId);
    sessionStorage.setItem(storageKey, url);
}

function getRedirectUrl(accountId: Uuid): string | null {
    const storageKey = getRedirectUrlStorageKey(accountId);
    return sessionStorage.getItem(storageKey);
}

function removeRedirectUrl(accountId: Uuid) {
    const storageKey = getRedirectUrlStorageKey(accountId);
    sessionStorage.removeItem(storageKey);
}

function getRedirectUrlStorageKey(accountId: Uuid): string {
    return `AccountsService.${accountId}.lastViewedPage`;
}
