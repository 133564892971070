import { SubscriberPlan, SubscriberPlanPrice } from './subscriber-plan.interface';
import { PaymentGatewayName } from '@lib/shared-interface-payment-types';

export type FullSubscriberPlan = Omit<SubscriberPlan, 'pricing'> & {
    pricing: FullSubscriberPlanPrice[];

    /**
     * Is this plan currently being offered to new users?
     */
    active: boolean;
};

export type FullSubscriberPlanPrice = SubscriberPlanPrice & {
    /**
     * An array of payment gateways and their corresponding price ID for this price.
     */
    gatewayPriceRecords?: GatewayPriceRecord[];
};

export type GatewayPriceRecord = {
    /**
     * The name of the payment gateway.
     */
    gateway: PaymentGatewayName;

    /**
     * The price ID for this price on the payment gateway.
     */
    priceId: string;
};
