import { Format, MaxLength } from 'ts-decorator-json-schema-generator';
import { composeJsonSchemaDecorators } from './compose-json-schema-decorators';

/**
 * A decorator to apply common decorators for a V4 UUID.
 *
 * Todo:
 *   Use AJV plugin to allow to validate decorated formats, e.g. @Format('uuid').
 *   How to enable: https://rxdb.info/schema-validation.html#validate-ajv.
 *   Why to enable: https://github.com/ajv-validator/ajv-formats#formats.
 *   As an alternative, we could validate certain fields at a higher level.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function UuidFormat() {
    // This can eventually be validated with AJV.
    const format = Format('uuid');

    // If this is used for a primary key, a max length is required:
    // https://rxdb.info/rx-schema.html#example
    const maxLength = MaxLength(36);

    return composeJsonSchemaDecorators(format, maxLength);
}
