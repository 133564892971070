import { Component, Input } from '@angular/core';
import { IonAvatar, IonIcon } from '@ionic/angular/standalone';
import { Account } from '@lib/shared-interface-account';
import { addIcons } from 'ionicons';
import { personCircleOutline } from 'ionicons/icons';

@Component({
    standalone: true,
    selector: 'lib-account-avatar',
    templateUrl: './account-avatar.component.html',
    styleUrls: ['./account-avatar.component.scss'],
    imports: [IonAvatar, IonIcon],
})
export class AccountAvatarComponent {
    @Input() public width?: number;
    @Input() public fontSize = '1.2rem';
    @Input() public backgroundColor? = 'var(--ion-color-primary)';
    protected imageSource?: string;
    protected titleInitial?: string;

    @Input()
    public set account(account: Pick<Account, 'avatar' | 'label'> | undefined | null) {
        this.imageSource = account?.avatar;
        this.titleInitial = account?.label?.charAt(0).toUpperCase() ?? '';
    }

    public constructor() {
        addIcons({ personCircleOutline });
    }
}
