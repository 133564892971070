import { Component, OnInit } from '@angular/core';
import { filter, firstValueFrom, Observable, pipe, UnaryFunction } from 'rxjs';
import { Uuid } from '@lib/shared-interface-utility-types';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonRow,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonText,
    IonToolbar,
} from '@ionic/angular/standalone';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { ChildActivationEnd, Event, NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppService } from '@lib/didit-app-data-app-service';
import {
    AuthenticatedAccountReference,
    AuthenticationService,
} from '@lib/didit-authentication-data-authentication-service';
import { InsertRouteTitleDirective } from '@lib/shared-util-angular-route-title';
import { AccountAvatarComponent } from '@lib/didit-accounts-feature-account-avatar';
import { AccountsService } from '@lib/didit-accounts-data-accounts-service';
import { map } from 'rxjs/operators';
import { OnlineStatusService } from '@lib/didit-shared-data-online-status-service';
import { IfPlatformIsDirective } from '@lib/didit-shared-ui-platform-directives';
import { addIcons } from 'ionicons';
import {
    addOutline,
    chatboxEllipsesOutline,
    chatbubblesOutline,
    checkmarkCircleOutline,
    closeOutline,
    compassOutline,
    documentTextOutline,
    earOutline,
    helpOutline,
    informationCircleOutline,
    newspaperOutline,
    peopleOutline,
    trashOutline,
} from 'ionicons/icons';

@Component({
    standalone: true,
    selector: 'lib-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    imports: [
        AccountAvatarComponent,
        IfPlatformIsDirective,
        InsertRouteTitleDirective,
        IonButton,
        IonButtons,
        IonContent,
        IonHeader,
        IonIcon,
        IonItem,
        IonItemDivider,
        IonLabel,
        IonList,
        IonMenu,
        IonMenuButton,
        IonMenuToggle,
        IonRow,
        IonTabBar,
        IonTabButton,
        IonTabs,
        IonText,
        IonToolbar,
        RouterModule,
        TitleCasePipe,
        TranslateModule,
        AsyncPipe,
    ],
})
export class NavigationComponent implements OnInit {
    public readonly activeAccountReference$: Observable<AuthenticatedAccountReference | undefined> =
        this.appService.activeAccountReference$;
    public readonly inactiveAccountReferences$: Observable<AuthenticatedAccountReference[]> =
        this.appService.inactiveAccountReferences$;
    public showTabs$!: Observable<boolean>;
    public readonly offline$: Observable<boolean> = this.onlineStatus.isOffline$;
    public readonly accountLabel$ = this.accountsService.activeAccount$.pipe(
        map((account) => account?.label ?? ''),
    );

    public constructor(
        private readonly accountsService: AccountsService,
        private readonly appService: AppService,
        private readonly authenticationService: AuthenticationService,
        private readonly router: Router,
        private readonly onlineStatus: OnlineStatusService,
    ) {
        addIcons({
            addOutline,
            chatboxEllipsesOutline,
            chatbubblesOutline,
            checkmarkCircleOutline,
            closeOutline,
            compassOutline,
            documentTextOutline,
            earOutline,
            helpOutline,
            informationCircleOutline,
            newspaperOutline,
            peopleOutline,
            trashOutline,
        });
    }

    public ngOnInit(): void {
        const event$ = this.router.events;
        this.showTabs$ = event$.pipe(
            getActivatedChildRouteData(),
            map((data) => {
                return !data['hideTabNavigation'];
            }),
        );
        event$.subscribe((navigationEvent) => {
            if (navigationEvent instanceof NavigationEnd)
                void this.accountsService.saveLastViewedPage(navigationEvent.url);
        });
    }

    public setActiveAccount(accountId: Uuid | undefined): Promise<void> {
        return this.accountsService.setActive(accountId);
    }

    public async logOutAllAccounts(): Promise<void> {
        this.authenticationService.signOutAllAccounts();
        void this.accountsService.clearAllLastViewedPages();

        const activeAccount = await firstValueFrom(this.accountsService.activeAccount$);

        await this.setActiveAccount(undefined);
        await this.router.navigate(['account', 'login'], {
            queryParams: { email: activeAccount?.email },
        });
    }
}

// TODO: refactor to shared library
function getActivatedChildRouteData<
    Value extends Record<string | symbol, unknown>,
>(): UnaryFunction<Observable<Event>, Observable<Value>> {
    return pipe(
        filter((event: Event): event is ChildActivationEnd => event instanceof ChildActivationEnd),
        map((event) => {
            let snapshot = event.snapshot;
            while (snapshot.firstChild) snapshot = snapshot.firstChild;
            return snapshot.data as Value;
        }),
    );
}
