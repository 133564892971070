import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import {
    ClientMetadata,
    CognitoUserAttribute,
    CognitoUserPool,
    IAuthenticationDetailsData,
    ISignUpResult,
    NodeCallback,
} from 'amazon-cognito-identity-js';
import { CognitoObjectFactory } from './cognito-object-factory.service';
import { Email, Uuid } from '@lib/shared-interface-utility-types';
import { AccountReference } from '@lib/didit-accounts-interface-account-reference';
import {
    CustomChallengeError,
    MfaRequiredError,
    MfaSetupError,
    NewPasswordRequiredError,
    SelectMfaTypeError,
    TotpRequiredError,
} from './custom-errors';
import { LOCAL_STORAGE } from '@lib/shared-util-angular-globals';
import { GraphqlClientService } from '@lib/didit-authentication-graphql-client';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private tokenRenewalSubscription?: Subscription;

    /**
     * Emits whenever any Account's state is changed and when new Access and ID tokens are acquired.
     */
    private readonly authenticationStates = new BehaviorSubject<AuthenticationState[]>([]);
    public readonly authenticationStates$ = this.authenticationStates.asObservable();

    /**
     * Emits whenever any Account's state is changed and when new Access and ID tokens are acquired.
     */
    private readonly authenticationActivity = new Subject<AuthenticationState>();
    public readonly authenticationActivity$ = this.authenticationActivity.asObservable();

    public constructor(
        private readonly cognitoObjectFactory: CognitoObjectFactory,
        @Inject(LOCAL_STORAGE) private readonly cognitoStorage: Storage,
        private readonly graphqlClient: GraphqlClientService,
        private readonly userPool: CognitoUserPool,
    ) {}

    public get loggedInAccounts(): AuthenticationState[] {
        const authenticationStates = this.authenticationStates.getValue();
        return authenticationStates.filter((state) => state.isLoggedIn);
    }

    public get lastAuthUserId(): Uuid | undefined {
        const key = this.lastAuthUserStorageKey;
        return this.cognitoStorage.getItem(key) ?? undefined;
    }

    private get lastAuthUserStorageKey(): string {
        const userPoolClientId = this.userPool.getClientId();
        return `CognitoIdentityServiceProvider.${userPoolClientId}.LastAuthUser`;
    }

    public stopTokenRenewal() {
        this.tokenRenewalSubscription?.unsubscribe();
    }

    public initializeSessions(authenticationIds: Uuid[]) {
        this.stopTokenRenewal();

        // Immediately run token renewal for all account references that we know about.
        // This will set the initial login state for each one.
        void this.renewTokens(authenticationIds);

        // Run token renewal every 4 minutes.
        // We have fixed this to 4 minutes because the minimum access token expiration time is 5 minutes.
        // Therefore, we will be forcing refresh with plenty of time no matter the access token expiration.
        // Todo: Eventually, a more accurate approach could be implemented which depends on actual expiration times.
        const fourMinutes = getMinutesInMilliseconds(4);
        const renewalTime$ = timer(fourMinutes, fourMinutes);
        this.tokenRenewalSubscription = renewalTime$.subscribe(() => void this.renewTokens());
    }

    /**
     * Signs in to Cognito.
     *
     * Returns a promise of login.
     * On success, it will update the appropriate entry within `accountsAuthenticationState$`
     * with `isLoggedIn: true`.
     * On failure, it will update the appropriate entry within `accountsAuthenticationState$`
     * with `isLoggedIn: false`.
     * It will request a new password if required.
     */
    public async signIn(credentials: SignInParameters): Promise<CognitoUserAttribute[]> {
        const email = credentials.username.toLowerCase();
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(email);
        const configuration = createAuthenticationDetailsData(credentials);
        const authenticationDetails =
            this.cognitoObjectFactory.getAuthenticationDetails(configuration);

        const authResult = await cognitoUser.authenticateUser(authenticationDetails);

        // Todo: We don't currently handle these authentication responses. We probably should.
        //  For now I just throw to signal that we aren't handling these situations.
        if (authResult.type === 'newPasswordRequired')
            throw new NewPasswordRequiredError(
                authResult.userAttributes,
                authResult.requiredAttributes,
            );
        if (authResult.type === 'mfaRequired')
            throw new MfaRequiredError(authResult.challengeName, authResult.challengeParameters);
        if (authResult.type === 'totpRequired')
            throw new TotpRequiredError(authResult.challengeName, authResult.challengeParameters);
        if (authResult.type === 'customChallenge')
            throw new CustomChallengeError(authResult.challengeParameters);
        if (authResult.type === 'mfaSetup')
            throw new MfaSetupError(authResult.challengeName, authResult.challengeParameters);
        if (authResult.type === 'selectMFAType')
            throw new SelectMfaTypeError(authResult.challengeName, authResult.challengeParameters);
        if (authResult.type === 'failure') throw authResult.error;

        const attributesResult = await cognitoUser.getUserAttributes();
        if (attributesResult.type === 'failure') throw attributesResult.error;

        this.setToLoggedIn(attributesResult.data);

        return attributesResult.data;
    }

    /**
     * Sets authenticationState for given id
     *
     * Checks for a Cognito session via valid refresh token.
     * if token is valid, a session is received,
     * if not, related account tokens are removed from storage
     *
     * The authenticationStates$ Observable will then be updated
     * with that accounts status loggedIn status accordingly.
     */
    public async setAuthenticationState(authenticationId: string): Promise<void> {
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(authenticationId);
        const resetLastAuthUser = this.setLastAuthUser(authenticationId);
        const setIsLoggedIn = (isLoggedIn: boolean) => {
            this.setAccountState(authenticationId, isLoggedIn);
            resetLastAuthUser();
        };
        const sessionResult = await cognitoUser.getSession();

        if (sessionResult.type === 'failure') {
            console.warn('Could not retrieve session:', sessionResult.error);
            setIsLoggedIn(false);
            return;
        }

        const refreshToken = sessionResult.data.getRefreshToken();
        const refreshResult = await cognitoUser.refreshSession(refreshToken);

        if (refreshResult.type === 'failure') {
            console.warn('Could not refresh session:', refreshResult.error);
            setIsLoggedIn(false);
            return;
        }

        setIsLoggedIn(true);
    }

    /**
     * Signs Out of Cognito
     *
     * Provided an authenticationId, this will log out the given user,
     * invalidating their session and removing their tokens from storage.
     * The authenticationStates BehaviorSubject and Observable will be updated accordingly.
     * @param authenticationId
     * */
    public async signOut(authenticationId: string): Promise<void> {
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(authenticationId);
        await cognitoUser.signOut();
        this.setAccountState(authenticationId, false);
    }

    public signOutAllAccounts(): void {
        const loggedInAccounts = this.loggedInAccounts;
        for (const account of loggedInAccounts) {
            void this.signOut(account.authenticationId);
        }
    }

    public getAccessToken(authenticationId: string): string | undefined {
        const accessTokenStorageKey = `CognitoIdentityServiceProvider.${this.userPool.getClientId()}.${authenticationId}.accessToken`;
        return this.cognitoStorage.getItem(accessTokenStorageKey) ?? undefined;
    }

    /**
     * Send a password reset code.
     *
     * Uses Cognito to send a confirmation code for resetting a password.
     * This is successful even if no Cognito user exists for the email.
     */
    public async requestPasswordReset(email: string): Promise<void> {
        email = email.toLowerCase();

        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(email);
        const result = await cognitoUser.forgotPassword();
        if (result.type === 'failure') throw result.error;
    }

    /**
     * Sends a confirmation to Cognito to reset the password of the given username.
     *
     * @param authIdOrEmail This is the email alias, so this method is expecting the email as the username input
     * @param verificationCode This is of length 6;
     * @param newPassword
     */
    public async confirmPassword(
        authIdOrEmail: string,
        verificationCode: string,
        newPassword: string,
    ): Promise<string> {
        authIdOrEmail = authIdOrEmail.toLowerCase();
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(authIdOrEmail);
        const result = await cognitoUser.confirmPassword(verificationCode, newPassword);
        if (result.type === 'failure') throw result.error;

        return result.data;
    }

    /**
     * @param email This is the email alias, so this method is expecting the email as the username input
     *
     * More info to come when registration is added. I think this one is related to that method.
     */
    public async resendConfirmationCode(email: string): Promise<unknown> {
        email = email.toLowerCase();
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(email);
        const result = await cognitoUser.resendConfirmationCode();
        if (result.type === 'failure') throw result.error;

        return result.data;
    }

    /**
     * Sends a request to Cognito to register a new account
     */
    public register(email: string, password: string): Promise<ISignUpResult> {
        email = email.toLowerCase();
        const emailAttribute = this.cognitoObjectFactory.createUserAttribute('email', email);

        return this.signUp(email, password, [emailAttribute], []);
    }

    public async confirmRegistration(
        authIdOrEmail: Uuid,
        code: string,
        forceAliasCreation = true,
    ): Promise<void> {
        authIdOrEmail = authIdOrEmail.toLowerCase();
        const cognitoUser = this.cognitoObjectFactory.getCognitoUser(authIdOrEmail);
        const result = await cognitoUser.confirmRegistration(code, forceAliasCreation);
        if (result.type === 'failure') throw result.error;
    }

    public async requestEmailChange(newEmail: Email): Promise<void> {
        newEmail = newEmail.toLowerCase();

        const emailAttribute = this.cognitoObjectFactory.createUserAttribute('email', newEmail);
        const cognitoUser = this.cognitoObjectFactory.getCurrentCognitoUser();
        // Calling get session populates authentication details for subsequent calls
        // See https://github.com/aws-amplify/amplify-js/issues/8064#issuecomment-938017620.
        const sessionResult = await cognitoUser.getSession();
        if (sessionResult.type === 'failure') throw sessionResult.error;

        const attributesResult = await cognitoUser.getUserAttributes();
        if (attributesResult.type === 'failure') throw attributesResult.error;

        const currentEmail = getUserAttribute('email', attributesResult.data);
        if (currentEmail == undefined) throw new Error('Could not confirm current email.');
        if (currentEmail === newEmail) throw new Error(`Email already set to ${newEmail}.`);

        const updateResult = await cognitoUser.updateAttributes([emailAttribute]);
        if (updateResult.type === 'failure') throw updateResult.error;
    }

    public async verifyEmailChange(confirmationCode: string) {
        const cognitoUser = this.cognitoObjectFactory.getCurrentCognitoUser();

        // Calling get session populates authentication details for subsequent calls
        // See https://github.com/aws-amplify/amplify-js/issues/8064#issuecomment-938017620.
        const sessionResult = await cognitoUser.getSession();
        if (sessionResult.type === 'failure') throw sessionResult.error;

        await this.graphqlClient.assertReachable();
        await cognitoUser.verifyAttribute('email', confirmationCode);
        await this.graphqlClient.updateAccountEmail();
    }

    private signUp(
        email: string,
        password: string,
        userAttributes: CognitoUserAttribute[],
        validationData: CognitoUserAttribute[],
        clientMetadata?: ClientMetadata,
    ): Promise<ISignUpResult> {
        // This seems to be the only callback-style method that we use
        // from the user pool at the moment.
        // If we find we need to use more of them, it may be worth wrapping the user pool service.
        return new Promise<ISignUpResult>((resolve, reject) => {
            const callback: NodeCallback<Error, ISignUpResult> = (error, result) => {
                if (error) return reject(error);
                if (result) return resolve(result);
                throw new Error('No error or result during signup.');
            };
            this.userPool.signUp(
                email,
                password,
                userAttributes,
                validationData,
                callback,
                clientMetadata,
            );
        });
    }

    private async renewTokens(authenticationIds?: Uuid[]) {
        authenticationIds ??= this.authenticationStates
            .getValue()
            .filter(({ isLoggedIn }) => isLoggedIn)
            .map(({ authenticationId }) => authenticationId);

        for (const id of authenticationIds) await this.setAuthenticationState(id);
    }

    private setAccountState(authenticationId: string, isLoggedIn: boolean): void {
        const userAttributes: AuthenticationState = {
            authenticationId,
            isLoggedIn,
        };
        const authenticationStatesMinusGivenAccount = this.authenticationStates
            .getValue()
            .filter(filterOutAccountById(authenticationId));
        this.authenticationStates.next([...authenticationStatesMinusGivenAccount, userAttributes]);
        this.authenticationActivity.next(userAttributes);
    }

    private setToLoggedIn(cognitoUserAttributes: CognitoUserAttribute[]): void {
        const authenticationId = getUserAttribute('sub', cognitoUserAttributes);
        if (!authenticationId)
            throw new Error('Authentication is broken and may not work until it is fixed.');
        void this.setAuthenticationState(authenticationId);
    }

    private setLastAuthUser(authenticationId: Uuid) {
        const originalId = this.lastAuthUserId;
        const key = this.lastAuthUserStorageKey;
        this.cognitoStorage.setItem(key, authenticationId);

        // It's important to reset the last auth user after authentication
        // so that we can rely on it on initial page load if necessary.
        if (originalId === undefined) return () => this.cognitoStorage.removeItem(key);
        return () => this.cognitoStorage.setItem(key, originalId);
    }
}

function getMinutesInMilliseconds(minutes: number): number {
    return minutes * 60 * 1000;
}

function getUserAttribute(
    name: string,
    cognitoUserAttributes: CognitoUserAttribute[],
): string | undefined {
    return cognitoUserAttributes.find((attribute: CognitoUserAttribute) => attribute.Name === name)
        ?.Value;
}

function filterOutAccountById(authenticationId: string): (account: AuthenticationState) => boolean {
    return (account: AuthenticationState) => account.authenticationId !== authenticationId;
}

function createAuthenticationDetailsData(
    credentials: SignInParameters,
): IAuthenticationDetailsData {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
        Username: credentials.username.toLowerCase(),
        Password: credentials.password,
        ValidationData: {
            code: credentials.code,
        },
    };
    /* eslint-enable @typescript-eslint/naming-convention */
}

export interface AuthenticationState {
    authenticationId: string;
    isLoggedIn: boolean;
}

/**
 * Reference to an authenticated account.
 *
 * Does not include full account data.
 */
export interface AuthenticatedAccountReference extends AccountReference {
    /**
     * Whether the referenced account is logged in or not
     * as identified by the authentication service.
     */
    isLoggedIn: boolean;
    /**
     * Whether the account is active or not as identified in session storage.
     */
    isActive: boolean;
}

interface SignInParameters {
    username: string;
    password: string;
    code?: string | undefined;
}
