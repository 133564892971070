import { Uuid } from '@lib/shared-interface-utility-types';
import { RxGraphQLReplicationPullQueryBuilder, RxGraphQLReplicationPushQueryBuilder } from 'rxdb';
import { ReplicationPushRow } from '@lib/shared-interface-rxdb-replication-types';

export function getPullQueryBuilder<Checkpoint>(
    query: string,
    constants: {
        accountId: Uuid;
        limit?: number;
    },
): RxGraphQLReplicationPullQueryBuilder<Checkpoint> {
    const { accountId, limit } = constants;
    return (checkpoint?: Checkpoint) => ({
        query,
        variables: { accountId, checkpoint, limit },
    });
}

export function getPushQueryBuilder<ReplicatedDocument>(
    query: string,
    constants: {
        accountId: Uuid;
    },
): RxGraphQLReplicationPushQueryBuilder {
    const { accountId } = constants;
    return (rows: ReplicationPushRow<ReplicatedDocument>[]) => ({
        query,
        variables: { accountId, rows },
    });
}

export function getPullStreamQueryBuilder(
    query: string,
    constants: {
        accountId: Uuid;
    },
) {
    const { accountId } = constants;
    // Headers only need to be used if we reach the point of authenticating individual accounts.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (headers: Record<string, string>) => {
        const authorization = headers['authorization'];
        return { query, variables: { accountId, authorization } };
    };
}

export const REPLICATION_CHECKPOINT_GQL_FRAGMENT = /* GraphQL */ `
    fragment ReplicationCheckpointParts on ReplicationCheckpoint {
        id
        updatedAt
    }
`;
