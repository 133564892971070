import { Jsonify } from 'type-fest';
import { Account } from '@lib/shared-interface-account';

export type RxdbAccount = Jsonify<Account>;

export function reviveRxdbAccount(rxdbAccount: RxdbAccount): Account {
    const updatedAt = new Date(rxdbAccount.updatedAt);

    return { ...rxdbAccount, updatedAt };
}

export type PatchAccountInput = Partial<Omit<Account, 'id' | 'updatedAt' | 'email'>>;
