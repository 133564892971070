import { DateTimeZone, Email, Uuid } from '@lib/shared-interface-utility-types';
import { createStringEnum, EnumValues } from '@lib/shared-util-create-enum';
import { RecurrencePattern } from '@lib/shared-util-recurrence-pattern';

/**
 * Properties of an ask shared between the client and server.
 */
export interface Ask {
    /**
     * Unique ID for this ask.
     */
    id: Uuid;
    /**
     * The id for the account which created this ask.
     */
    creatorId: Uuid;
    /**
     * The date and time when this ask was created.
     */
    createdAt: Date;
    /**
     * The date and time when this ask was last updated.
     */
    updatedAt: Date;
    /**
     * A title to quickly denote the topic of this ask.
     */
    title: string;
    /**
     * A fuller description further explaining the title of this ask.
     */
    description?: string;
    /**
     * The individual requesting this ask if different from the creator.
     *
     * A requester is essentially a primary watcher.
     */
    requester?: Requester;
    /**
     * The list of recipients and data about their response to this ask.
     */
    recipients: Recipient[];
    /**
     * The approximate date by which the requester wishes this ask to be completed.
     */
    whenRequestedBy?: DateTimeZone;
    /**
     * Recurrence pattern for the ask's whenRequestedBy date, if applicable
     */
    recurrencePattern?: RecurrencePattern;
    /**
     * The sequence of changes to the status of this ask.
     *
     * Defaults to `AskStatus.DRAFT`.
     */
    statusChanges: AskStatusChange[];
    /**
     * Whether the ask has been sent.
     */
    sentAt?: Date;
    /**
     * When the ask was trashed.
     *
     */
    trashedAt?: Date;
    /**
     * When the ask was marked completed.
     *
     * This is a helper for sorting purposes. If the creator alters the status from COMPLETED,
     * this field should be cleared out again.
     */
    completedAt?: Date;
    /**
     * The AskStatus Enum value of the latest StatusChange in the statusChanges array.
     *
     */
    currentStatus: AskStatus;
    /**
     * The strategy for creating assignments.
     */
    assignmentStrategy?: AssignmentStrategy;
}

/**
 * Assignment strategy enum.
 *
 * - MULTIPLE means every recipient will have an assignment.
 * - SINGLE means one person can claim the assignment.
 * - SINGLE_SHARED means everyone collaborates on only one assignment.
 */
export const AssignmentStrategy = createStringEnum(['MULTIPLE', 'SINGLE', 'SINGLE_SHARED']);
export type AssignmentStrategy = EnumValues<typeof AssignmentStrategy>;
export const ASSIGNMENT_STRATEGIES: AssignmentStrategy[] = Object.values(AssignmentStrategy);

/**
 * Information about a requester in relation to a specific ask.
 */
export interface Requester {
    /**
     * The account id of the requester.
     */
    accountId: Uuid;
    /**
     * An optional response regarding how the requester has explicitly responded to their role.
     */
    acceptance?: AcceptanceType;
}

/**
 * Values for whether a role or task has been accepted or rejected.
 */
export const AcceptanceType = createStringEnum(['ACCEPTED', 'REJECTED']);
export type AcceptanceType = EnumValues<typeof AcceptanceType>;
export const ACCEPTANCE_TYPES: AcceptanceType[] = Object.values(AcceptanceType);

/**
 * Information a recipient in relation to a specific ask.
 */
export interface Recipient {
    /**
     * The account id of the assignee.
     */
    accountId?: Uuid;
    /**
     * The ID of the contact from the creator's contact list.
     */
    contactId?: Uuid;
    /**
     * The name of the assignee copied from their account at the time of assignment.
     */
    name: string;
    /**
     * The email of the assignee copied from their account at the time of assignment.
     */
    email: Email;
    /**
     * Whether the recipient is a watcher.
     */
    watcher: boolean;
    /**
     * Whether the recipient is an assignee.
     */
    assignee: boolean;
    /**
     * Whether the recipient is an editor.
     */
    editor: boolean;
    /**
     * An approximate date by which the recipient intends to complete the assigned ask.
     *
     * Only meaningful for assignees.
     */
    whenPlannedBy?: DateTimeZone;
    /**
     * The sequence of changes to the status of this ask from this recipient's perspective.
     */
    statusChanges: RecipientAskStatusChange[];
    /**
     * Whether the assignee considers the assigned ask to be complete from their perspective.
     */
    completed: boolean;
}

/**
 * A change to the status of an ask.
 */
export interface AskStatusChange {
    /**
     * The date and time when this status change was made.
     */
    timestamp: Date;
    /**
     * The status of the ask at the time of this status change.
     */
    status: AskStatus;
    /**
     * An optional comment regarding this status change.
     *
     * Any status can include a short note, i.e. a brief explanation of why the creator
     * put the Ask ON-HOLD or why they CANCELED it.
     */
    note?: string;
}

/**
 * The status of an ask.
 *
 * - DRAFT can change to OPEN (when didit is assigned)
 * - OPEN can change to ON_HOLD | CANCELED | COMPLETED
 * - ON-HOLD can change to OPEN | CANCELED | COMPLETED
 * - CANCELED can change to OPEN
 * - COMPLETED can change to OPEN
 */
export const AskStatus = createStringEnum(['DRAFT', 'OPEN', 'ON_HOLD', 'CANCELED', 'COMPLETED']);
export type AskStatus = EnumValues<typeof AskStatus>;
export const ASK_STATUSES: AskStatus[] = Object.values(AskStatus);

/**
 * A change to the status of an ask from the perspective of a recipient.
 */
export interface RecipientAskStatusChange {
    /**
     * The date and time when this status change was made.
     */
    timestamp: Date;
    /**
     * The recipient status of the ask at the time of this status change.
     */
    status: RecipientAskStatus;
    /**
     * An optional comment regarding this status change.
     *
     * Any status can include a short note, i.e. a brief explanation of why the creator
     * put the Ask ON-HOLD or why they CANCELED it.
     */
    note?: string;
}

/**
 * The status of an ask from the perspective of a recipient.
 *
 * - Assignees can have: REFUSED | ACCEPTED | IN_PROGRESS | ON_HOLD | CANCELED | COMPLETED.
 * - Watchers who are not also assignees only have: REFUSED | ACCEPTED
 * - REFUSED can change to ACCEPTED
 * - ACCEPTED | IN_PROGRESS can change to ON_HOLD | CANCELED | COMPLETED
 * - ON-HOLD can change to IN_PROGRESS | CANCELED | COMPLETED
 * - CANCELED can change to IN_PROGRESS
 * - COMPLETED can change to IN_PROGRESS
 */
export const RecipientAskStatus = createStringEnum([
    'REFUSED',
    'ACCEPTED',
    'IN_PROGRESS',
    'ON_HOLD',
    'CANCELED',
    'COMPLETED',
]);
export type RecipientAskStatus = EnumValues<typeof RecipientAskStatus>;
export const RECIPIENT_ASK_STATUSES: RecipientAskStatus[] = Object.values(RecipientAskStatus);
