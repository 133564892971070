import {
    Default,
    Enum,
    Format,
    Items,
    JsonSchema,
    MinLength,
    Optional,
    Required,
    Type,
} from 'ts-decorator-json-schema-generator';
import { DateTimeZone, Uuid } from '@lib/shared-interface-utility-types';
import { generateRxdbSchema } from '../../shared/generate-rxdb-schema';
import { UuidFormat } from '../../shared/uuid-format.decorator';
import { RxdbAsk, RxdbAskRecipient, RxdbAskStatusChange } from './rxdb-ask.interface';
import { DateTimeZoneSchema } from './date-time-zone.schema';
import { RequesterSchema } from './requester.schema';
import {
    ASK_STATUSES,
    AskStatus,
    ASSIGNMENT_STRATEGIES,
    AssignmentStrategy,
    Requester,
} from '@lib/shared-interface-ask';
import { StatusChangesSchema } from './status-changes.schema';
import { RecipientSchema } from './recipient.schema';

@JsonSchema({
    title: 'Ask Schema',
    description: 'An ask.',
})
class AskSchema implements RxdbAsk {
    @Required()
    @UuidFormat()
    public readonly id!: Uuid;

    @Required()
    @UuidFormat()
    public readonly creatorId!: Uuid;

    @Required()
    @Format('date-time')
    public readonly createdAt!: string;

    @Required()
    @Format('date-time')
    public readonly updatedAt!: string;

    @Required()
    @MinLength(1)
    public readonly title!: string;

    @Optional()
    public readonly description?: string;

    @Optional()
    @Type(RequesterSchema)
    public readonly requester?: Requester;

    @Optional()
    @Items(RecipientSchema)
    @Default([])
    public readonly recipients!: RxdbAskRecipient[];

    @Optional()
    @Type(DateTimeZoneSchema)
    public readonly whenRequestedBy?: DateTimeZone;

    @Optional()
    public readonly recurrencePattern?: string;

    @Required()
    @Items(StatusChangesSchema)
    @Default([])
    public readonly statusChanges!: RxdbAskStatusChange[];

    @Optional()
    @Format('date-time')
    public readonly sentAt?: string;

    @Optional()
    @Format('date-time')
    public readonly trashedAt?: string;

    @Optional()
    @Format('date-time')
    public readonly completedAt?: string;

    @Required()
    @Enum(ASK_STATUSES, 'string')
    public readonly currentStatus!: AskStatus;

    @Optional()
    @Enum(ASSIGNMENT_STRATEGIES, 'string')
    public readonly assignmentStrategy?: AssignmentStrategy;
}

export const ASK_SCHEMA = generateRxdbSchema(AskSchema, {
    primaryKey: 'id',
    // If you do not increment this version, you may need to wipe your database.
    // Once in production, incrementing this number will require migrations.
    version: 0,
    // Key compression doesn't work with fake-indexeddb, so it is turned off for now.
    // If we need key compression at some point, we can probably selectively turn it off for tests.
    keyCompression: false,
});
