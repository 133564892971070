<ion-menu contentId="navigation-router-outlet" menuId="main" side="start">
    <ion-header>
        <ion-toolbar>
            <picture slot="start">
                <source
                    media="(prefers-color-scheme:dark)"
                    srcset="/assets/logos/logo-icon-name-dark-mode.svg"
                />
                <img
                    alt="Logo icon and name"
                    class="icon-full cursor-pointer"
                    routerLink="/"
                    routerDirection="root"
                    src="/assets/logos/logo-icon-name.svg"
                />
            </picture>
            <ion-buttons slot="end">
                <ion-menu-toggle menu="main">
                    <ion-button>
                        <ion-icon name="close-outline" slot="icon-only" />
                    </ion-button>
                </ion-menu-toggle>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <ion-item-divider>
            <ion-label> App</ion-label>
        </ion-item-divider>
        <ion-menu-toggle menu="main">
            <ion-list lines="none">
                <ion-item
                    button
                    routerLink="/contacts"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label> {{ 'navigation.mobile.contacts' | translate }} </ion-label>
                    <ion-icon name="people-outline" slot="start" />
                </ion-item>
                <ion-item
                    button
                    routerLink="/drafts"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label>Drafts</ion-label>
                    <ion-icon name="document-text-outline" slot="start" />
                </ion-item>
                <ion-item
                    button
                    routerLink="/trash"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label>Trash</ion-label>
                    <ion-icon name="trash-outline" slot="start" />
                </ion-item>
            </ion-list>
        </ion-menu-toggle>
        <ion-item-divider>
            <ion-label> Information</ion-label>
        </ion-item-divider>
        <ion-menu-toggle menu="main">
            <ion-list lines="none">
                <ion-item
                    button
                    routerLink="/information/usage"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label> {{ 'navigation.mobile.howToUseDidIt' | translate }} </ion-label>
                    <ion-icon name="help-outline" slot="start" />
                </ion-item>
                <ion-item
                    button
                    routerLink="/information/feedback"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label> {{ 'navigation.mobile.feedback' | translate }} </ion-label>
                    <ion-icon name="chatbox-ellipses-outline" slot="start" />
                </ion-item>
                <ion-item
                    button
                    routerLink="/information/whats-new"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label> {{ 'navigation.mobile.whatsNew' | translate }} </ion-label>
                    <ion-icon name="newspaper-outline" slot="start" />
                </ion-item>
                <ion-item
                    button
                    routerLink="/information/share"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label> {{ 'navigation.mobile.tellAFriend' | translate }} </ion-label>
                    <ion-icon name="chatbubbles-outline" slot="start" />
                </ion-item>
                <ion-item
                    button
                    routerLink="/information/about"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label> {{ 'navigation.mobile.about' | translate }} </ion-label>
                    <ion-icon name="information-circle-outline" slot="start" />
                </ion-item>
                <ion-item
                    button
                    routerLink="/information/privacy-policy"
                    routerLinkActive="selected"
                    routerDirection="root"
                >
                    <ion-label> {{ 'navigation.mobile.privacyPolicy' | translate }} </ion-label>
                    <ion-icon name="ear-outline" slot="start" />
                </ion-item>
            </ion-list>
        </ion-menu-toggle>
    </ion-content>
</ion-menu>

<ion-menu contentId="navigation-router-outlet" menuId="account-navigation" side="end">
    <ion-content class="account-navigation-container menu-light-contrast">
        <ion-header class="ion-no-border">
            <ion-toolbar class="menu-light-contrast">
                @if (activeAccountReference$ | async; as accountReference) {
                    <ion-row
                        class="active-account-menu-header ion-align-items-center gradient-background ion-padding-top"
                    >
                        <lib-account-avatar
                            class="active-account-avatar ion-padding-start"
                            [width]="48"
                            fontSize="2rem"
                            [account]="accountReference"
                        />
                        <div>
                            <div class="active-account-label">
                                {{ accountReference.label }}
                            </div>
                            <div>
                                <small class="active-account-full-name">
                                    {{ accountReference.fullName }}
                                    @if (offline$ | async) {
                                        <span> - Offline</span>
                                    }
                                </small>
                            </div>
                        </div>
                    </ion-row>
                }
            </ion-toolbar>
        </ion-header>

        @if (activeAccountReference$ | async; as accountReference) {
            <ion-menu-toggle menu="account-navigation">
                <ion-list lines="none" class="ion-no-padding">
                    <ion-item
                        button
                        detail="false"
                        routerLink="/account/details"
                        routerLinkActive="selected"
                        routerDirection="root"
                    >
                        <ion-icon src="assets/icons/account_circle.svg" slot="end" />
                        <ion-label>
                            {{ 'navigation.account.profile' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item
                        button
                        detail="false"
                        routerLink="/account/preferences"
                        routerLinkActive="selected"
                        routerDirection="root"
                    >
                        <ion-icon src="assets/icons/manage_accounts.svg" slot="end" />
                        <ion-label>
                            {{ 'navigation.account.preferences' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item
                        button
                        detail="false"
                        routerLink="/account/manage"
                        routerLinkActive="selected"
                        routerDirection="root"
                    >
                        <ion-icon src="assets/icons/dashboard.svg" slot="end" />
                        <ion-label>
                            {{ 'navigation.account.myAccount' | translate }}
                        </ion-label>
                    </ion-item>
                    <!-- TODO: Remove for paid users -->
                    <ion-item
                        button
                        detail="false"
                        routerLink="/account/subscription"
                        routerLinkActive="selected"
                        routerDirection="root"
                    >
                        <ion-icon
                            class="plans-icon"
                            src="assets/icons/plan-selection.svg"
                            slot="end"
                        />
                        <ion-label>
                            {{ 'navigation.account.upgrade' | translate }}
                        </ion-label>
                    </ion-item>
                    @if (!accountReference.isLoggedIn) {
                        <ion-item
                            [disabled]="offline$ | async"
                            [queryParams]="{ email: accountReference.email }"
                            button
                            detail="false"
                            lines="full"
                            routerDirection="root"
                            routerLink="/account/login"
                            routerLinkActive="selected"
                        >
                            <ion-icon src="assets/icons/login.svg" slot="end" />
                            <ion-label> {{ 'navigation.account.logIn' | translate }} </ion-label>
                        </ion-item>
                    } @else {
                        <!-- Todo: click handler to log out selected account -->
                        <ion-item
                            (click)="logOutAllAccounts()"
                            [disabled]="offline$ | async"
                            button
                            detail="false"
                            lines="full"
                        >
                            <ion-icon src="assets/icons/logout.svg" slot="end" />
                            <ion-label> {{ 'navigation.account.logOut' | translate }} </ion-label>
                        </ion-item>
                    }
                </ion-list>
            </ion-menu-toggle>
        }

        <ion-list lines="none" class="menu-light-contrast ion-no-padding">
            <ion-item class="menu-light-contrast">
                <ion-label> Other Accounts </ion-label>
            </ion-item>
            <ion-menu-toggle menu="account-navigation">
                @for (
                    accountReference of inactiveAccountReferences$ | async;
                    track accountReference.id
                ) {
                    <ion-item
                        (click)="setActiveAccount(accountReference.id)"
                        button
                        class="menu-light-contrast"
                        detail="false"
                    >
                        <lib-account-avatar
                            [width]="24"
                            [account]="accountReference"
                            fontSize=".7rem"
                            slot="start"
                        />
                        <ion-label>
                            {{ accountReference.label ?? accountReference.email }}
                        </ion-label>
                        @if (!accountReference.isLoggedIn) {
                            <ion-text slot="end" color="medium">
                                <small>Logged Out</small>
                            </ion-text>
                        }
                    </ion-item>
                }
                <ion-item
                    button
                    detail="false"
                    routerLink="/account/login"
                    routerDirection="root"
                    class="menu-light-contrast"
                    (click)="setActiveAccount(undefined)"
                >
                    <ion-icon name="add-outline" slot="start" />
                    <ion-label>
                        {{ 'navigation.account.addRegisteredAccount' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item
                    button
                    class="menu-light-contrast"
                    detail="false"
                    routerLink="/account/register"
                    routerDirection="root"
                    (click)="setActiveAccount(undefined)"
                >
                    <ion-icon src="assets/icons/how_to_reg.svg" slot="start" />
                    <ion-label>
                        {{ 'navigation.account.registerNewAccount' | translate }}
                    </ion-label>
                </ion-item>
            </ion-menu-toggle>
        </ion-list>
    </ion-content>
</ion-menu>

<ion-header *libIfPlatformIs="'desktop'">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button menu="main" color="primary" />
        </ion-buttons>
        <picture class="ion-hide-md-down" slot="start">
            <source
                media="(prefers-color-scheme:dark)"
                srcset="/assets/logos/logo-icon-name-dark-mode.svg"
            />
            <img
                alt="Logo icon and name"
                class="icon-full cursor-pointer"
                routerLink="/dashboard"
                src="/assets/logos/logo-icon-name.svg"
            />
        </picture>
        <picture class="ion-hide-md-up ion-hide-sm-down" slot="start">
            <source
                media="(prefers-color-scheme:dark)"
                srcset="/assets/logos/logo-icon-only-dark-mode.svg"
            />
            <img
                alt="Logo icon"
                class="icon-only cursor-pointer"
                routerLink="/dashboard"
                slot="start"
                src="/assets/logos/logo-icon-only.svg"
            />
        </picture>
        <ion-buttons class="large-screen-navigation ion-justify-content-start">
            <ion-button routerLink="/todo" routerLinkActive="selected" routerDirection="root">
                <ion-label> {{ 'navigation.primary.todo' | translate }}</ion-label>
            </ion-button>
            <ion-button routerLink="/watching" routerLinkActive="selected" routerDirection="root">
                <ion-label> {{ 'navigation.primary.watching' | translate }} </ion-label>
            </ion-button>
            <ion-button routerLink="/done" routerLinkActive="selected" routerDirection="root">
                <ion-label> {{ 'navigation.primary.done' | translate }} </ion-label>
            </ion-button>
            <ion-button routerLink="/explore" routerLinkActive="selected" routerDirection="root">
                <ion-label> {{ 'navigation.primary.explore' | translate }} </ion-label>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="end" class="account-avatar-container">
            <ion-menu-toggle menu="account-navigation">
                <ion-button>
                    <lib-account-avatar
                        slot="icon-only"
                        [width]="28"
                        [account]="activeAccountReference$ | async"
                    />
                </ion-button>
            </ion-menu-toggle>
            <span class="account-label">{{ accountLabel$ | async }}</span>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content id="navigation-router-outlet">
    <ion-tabs>
        @if (showTabs$ | async) {
            <ion-tab-bar *libIfPlatformIs="'mobile'" slot="bottom">
                <ion-tab-button tab="todo">
                    <ion-label>
                        {{ 'navigation.primary.todo' | translate }}
                    </ion-label>
                    <ion-icon src="/assets/icons/checklist.svg" />
                </ion-tab-button>
                <ion-tab-button tab="watching">
                    <ion-label>
                        {{ 'navigation.primary.watching' | translate }}
                    </ion-label>
                    <ion-icon src="/assets/icons/eyeglasses.svg" />
                </ion-tab-button>
                <ion-tab-button tab="dashboard">
                    <picture>
                        <source
                            media="(prefers-color-scheme:dark)"
                            srcset="/assets/logos/logo-icon-only-dark-mode.svg"
                        />
                        <img
                            alt="didit logo"
                            class="logo-icon"
                            src="/assets/logos/logo-icon-only.svg"
                        />
                    </picture>
                </ion-tab-button>
                <ion-tab-button tab="done">
                    <ion-label>
                        {{ 'navigation.primary.done' | translate }}
                    </ion-label>
                    <ion-icon name="checkmark-circle-outline" />
                </ion-tab-button>
                <ion-tab-button tab="explore">
                    <ion-label>
                        {{ 'navigation.primary.explore' | translate }}
                    </ion-label>
                    <ion-icon name="compass-outline" />
                </ion-tab-button>
            </ion-tab-bar>
        }
    </ion-tabs>
</ion-content>
