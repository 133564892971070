/**
 * Compose multiple decorators into a single decorator.
 * @param decorators the list of decorators to compose.
 */
export function composeJsonSchemaDecorators(
    ...decorators: JsonSchemaDecorator[]
): JsonSchemaDecorator {
    return (target, propertyKey) => {
        for (const decorator of decorators) decorator(target, propertyKey);
    };
}
type JsonSchemaDecorator = (target: unknown, propertyKey?: string | undefined) => void;
