import { RRuleSet, Weekday } from 'rrule';

export const ERROR_TEXT = 'Recurrence pattern is invalid.  Please re-enter it.';

export function assertRruleSetValid(value: unknown): asserts value is RRuleSet {
    const rruleSet = value as undefined | Partial<RRuleSet>;
    const rrule = rruleSet?.rrules?.()[0];
    if (!rrule) {
        throw new TypeError(ERROR_TEXT);
    }
    if (!rrule.origOptions.dtstart || !rrule.origOptions.freq || !rrule.origOptions.interval) {
        throw new TypeError(ERROR_TEXT);
    }
}

// RRule's Weekday stuff is a messy data structure.
// This type guard on their type tries to ensure some degree of uniformity.
export function isRruleWeekday(value: unknown): value is Weekday {
    if (value == undefined) {
        return false;
    }
    const weekday = value as Partial<Weekday>;
    if (!weekday.weekday) {
        return false;
    }
    if (!weekday.toString) {
        return false;
    }
    return true;
}
