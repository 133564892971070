import { Uuid } from '@lib/shared-interface-utility-types';
import { createStringEnum, EnumValues } from '@lib/shared-util-create-enum';

/**
 * Contains properties of a plan shared between the client and server.
 */
export type SubscriberPlan = {
    /**
     * Unique ID for this plan.
     */
    id: Uuid;

    /**
     * The title of the plan.
     */
    title: string;

    /**
     * A short description of the plan that will be shown to subscribing users.
     */
    description: string;

    /**
     * Different pricing schemes that users can choose from to pay for this plan.
     */
    pricing: SubscriberPlanPrice[];

    /**
     * The plan type ties this plan into business logic based around the plan
     */
    type: SubscriberPlanType;

    /**
     * Is this the default plan that should be selected when users register?
     */
    default: boolean;
};

export type SubscriberPlanPrice = {
    /**
     * Unique id of this SubscriberPlanPrice subdocument.
     */
    id: Uuid;

    /**
     * How often the plan is renewed.
     */
    renewalStrategy: RenewalStrategy;

    /**
     * The price of the plan for each period indicated by the renewalStrategy.
     */
    price: number;
};

export const SubscriberPlanType = createStringEnum([
    'BASIC',
    'PERSONAL',
    'PROFESSIONAL',
    'BUSINESS',
]);
export type SubscriberPlanType = EnumValues<typeof SubscriberPlanType>;

export const RenewalStrategy = createStringEnum(['MONTHLY', 'YEARLY']);
export type RenewalStrategy = EnumValues<typeof RenewalStrategy>;
