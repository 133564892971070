import { RecurrencePattern } from './recurrence-pattern';
import { RecurrenceOptions } from './types/recurrence-options-type';

export class RecurrencePatternBuilder {
    /**
     * Factory method to create Recurrence patterns
     * (class that wraps rrule.js)
     * from definition strings in storage
     * @param rrule An rrule string formatted
     * according to the Recurrence Rule specification.
     * See https://www.npmjs.com/package/rrule
     */
    public createFromRruleString(rrule: string): RecurrencePattern {
        return RecurrencePattern.createFromRruleString(rrule);
    }

    /**
     * Factory method to create Recurrence patterns
     * (class that wraps rrule.js)
     * from options available on the front end.
     * @param options.firstOccurrence The date and time of the first occurrence of the pattern
     * already in an appropriate timezone
     */
    public createFromOptions(options: RecurrenceOptions): RecurrencePattern {
        return RecurrencePattern.createFromOptions(options);
    }
}
