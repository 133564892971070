<div class="phone-number-flex-container">
    <ion-button
        (click)="openCountrySelectModal()"
        [disabled]="formGroup.controls.countryCode.disabled"
        fill="clear"
        type="button"
    >
        <img
            [ngSrc]="formGroup.controls.countryCode.value | libCountryFlagIcon"
            alt="flag icon"
            height="23"
            slot="icon-only"
            width="30"
        />
    </ion-button>
    <ion-input
        #phoneNumberInputElement
        (ionBlur)="notifyTouched?.()"
        [autocomplete]="autocomplete"
        [clearInput]="true"
        [formControl]="formGroup.controls.phoneNumber"
        [label]="label"
        [placeholder]="formGroup.controls.countryCode.value | libExamplePhoneNumber"
        aria-label="phone-number"
        inputmode="tel"
        labelPlacement="floating"
        mode="md"
    />
</div>
